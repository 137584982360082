import React from 'react'
import { Helmet } from 'react-helmet'

const Head = (props) => {

    return (
        <Helmet title="Stella Wong">
            <html lang="en" />
        </Helmet>
    )
}

export default Head